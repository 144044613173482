@layer utilities {
  /* ============= */
  /* Media queries */
  /* ============= */

  @media screen(sm) {
    .xs-only {
      display: none !important;
    }
  }

  @media screen(md) {
    .down-md {
      display: none !important;
    }
  }

  @media screen(lg) {
    .down-lg {
      display: none !important;
    }
  }

  @media screen(xl) {
    .xl-only {
      display: none !important;
    }
  }

  @media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
    .up-sm {
      display: none !important;
    }
  }

  @media screen and (max-width: calc(theme(screens.md) - 0.05px)) {
    .up-md {
      display: none !important;
    }
  }

  @media screen and (max-width: calc(theme(screens.lg) - 0.05px)) {
    .up-lg {
      display: none !important;
    }
  }

  @media screen and (max-width: calc(theme(screens.xl) - 0.05px)) {
    .up-xl {
      display: none !important;
    }
  }

  /* ============= */
  /* Shadow effect */
  /* ============= */

  .shadow-effect-sm {
    --shadow-depth: 6px;
  }

  .shadow-effect-xs {
    --shadow-depth: 3px;
  }

  .shadow-effect {
    box-shadow: 0 0 0 transparent;
    transition:
      box-shadow 0.2s,
      transform 0.2s;
  }

  .shadow-effect {
    transform: translate3d(
      calc(-0.5 * var(--shadow-depth, 8px)),
      calc(-0.5 * var(--shadow-depth, 8px)),
      0px
    );
    box-shadow: var(--shadow-depth, 8px) var(--shadow-depth, 8px) 0 rgb(var(--color-orange));
  }

  /* ============= */
  /* columns utils */
  /* ============= */

  .break-inside-avoid {
    break-inside: avoid;
  }

  /* ===== */
  /* Archs */
  /* ===== */

  .arch {
    --arch-size: 10;
    -webkit-mask-box-image-source: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCAzMCAzMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNLjIgOC41MyA4LjUzLjJjLjEyLS4xMy4zLS4yLjQ3LS4yaDEyYy4xOCAwIC4zNS4wOC40Ny4ybDguMzMgOC4zM2EuNjkuNjkgMCAwIDEgLjEyLjE2Yy4wNS4xLjA4LjIuMDguMzF2MTJhLjY3LjY3IDAgMCAxLS4yLjQ4bC04LjMzIDguMzNjLS4xMi4xMi0uMy4yLS40Ny4ySDEwTDkgMzBhLjY3LjY3IDAgMCAxLS40Ny0uMkwuMiAyMS40N0EuNjYuNjYgMCAwIDEgMCAyMVY5YzAtLjE3LjA3LS4zNC4yLS40N1oiIGZpbGw9IiMwMDMiLz4KPC9zdmc+Cg==);
    -webkit-mask-box-image-repeat: repeat;
    -webkit-mask-box-image-slice: 10 fill;
    -webkit-mask-box-image-width: calc(var(--arch-size) * 1px);
  }

  .arch-up {
    -webkit-mask-box-image-outset: 0 0 calc(var(--arch-size) * 1px);
  }

  .arch-down {
    -webkit-mask-box-image-outset: calc(var(--arch-size) * 1px) 0 0;
  }

  .arch-left {
    -webkit-mask-box-image-outset: 0 calc(var(--arch-size) * 1px) 0 0;
  }

  .arch-right {
    -webkit-mask-box-image-outset: 0 0 0 calc(var(--arch-size) * 1px);
  }

  .arch-xs {
    --arch-size: 6;
  }

  .arch-sm {
    --arch-size: 8;
  }

  .arch-lg {
    --arch-size: 20;
  }

  .arch-xl {
    --arch-size: 30;
  }

  .bg-dots {
    background-image: var(--asset-bg-section-background-image);
  }

  .no-link-underline {
    text-decoration: none !important;
    text-decoration-line: none !important;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .hide-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .hide-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .hide-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
  }
}

.group-hover\:shadow-effect,
.hover\:shadow-effect {
  transition:
    box-shadow 0.2s,
    transform 0.2s;
}
