/**
 * Do not edit directly
 * Generated on Wed, 21 Sep 2022 14:12:09 GMT
 */

:root {
  --shadow-2xl: 0px 100px 80px rgba(3, 27, 80, 0.07),0px 64.8148px 46.8519px rgba(3, 27, 80, 0.0531481),0px 38.5185px 25.4815px rgba(3, 27, 80, 0.0425185),0px 20px 13px rgba(3, 27, 80, 0.035),0px 8.14815px 6.51852px rgba(3, 27, 80, 0.0274815),0px 1.85185px 3.14815px rgba(3, 27, 80, 0.0168519);
  --color-blue-default: 0, 82, 255;
  --color-blue-900: 0, 0, 51;
  --color-blue-800: 0, 7, 93;
  --color-blue-700: 0, 23, 143;
  --color-blue-600: 0, 50, 206;
  --color-blue-500: 0, 82, 255;
  --color-blue-400: 45, 112, 255;
  --color-blue-300: 90, 142, 255;
  --color-blue-200: 134, 173, 255;
  --color-blue-100: 179, 203, 255;
  --color-blue-50: 224, 234, 255;
  --color-red-default: 239, 68, 68;
  --color-red-900: 127, 29, 29;
  --color-red-800: 153, 27, 27;
  --color-red-700: 185, 28, 28;
  --color-red-600: 220, 38, 38;
  --color-red-500: 239, 68, 68;
  --color-red-400: 248, 113, 113;
  --color-red-300: 252, 165, 165;
  --color-red-200: 254, 202, 202;
  --color-red-100: 254, 226, 226;
  --color-red-50: 254, 242, 242;
  --color-green-default: 34, 197, 94;
  --color-green-900: 20, 83, 45;
  --color-green-800: 22, 101, 52;
  --color-green-700: 21, 128, 61;
  --color-green-600: 22, 163, 74;
  --color-green-500: 34, 197, 94;
  --color-green-400: 74, 222, 128;
  --color-green-300: 134, 239, 172;
  --color-green-200: 187, 247, 208;
  --color-green-100: 220, 252, 231;
  --color-green-50: 240, 253, 244;
  --color-white: 255, 255, 255;
  --color-orange: 255, 105, 70;
  --color-mid-grey: 223, 223, 234;
  --color-snow-grey: 243, 243, 244;
  --color-storm-grey: 110, 118, 143;
  --color-fjord-grey: 70, 78, 103;
  --color-electric-blue: 0, 82, 255;
  --color-dark-blue: 0, 0, 51;
  --breakpoint-2xl: 1536;
  --breakpoint-xl: 1440;
  --breakpoint-lg: 1024;
  --breakpoint-md: 900;
  --breakpoint-sm: 600;
  --breakpoint-xs: 0;
  --asset-stylesheet-font-greycliff: "https://d3nwjb4s8t59v5.cloudfront.net/fonts/greycliff/style-6.css";
  --asset-stylesheet-font-saira: "https://fonts.googleapis.com/css2?family=Saira:ital,wdth,wght@0,100,600;0,125,700;1,125,700&display=swap";
  --asset-bg-section-background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAQAAAADQ4RFAAAAAXNSR0IArs4c6QAAABtJREFUeNpjGAWjYLCBm/dv3idP0ygYBYMMAACHRAbh+g6y4QAAAABJRU5ErkJggg==);
  --asset-favicon-dev-admin: "v1663155001/app/favicons/finishers-dev-favicon-admin";
  --asset-favicon-dev-default: "v1663155001/app/favicons/finishers-dev-favicon";
  --asset-favicon-staging-admin: "v1663155001/app/favicons/finishers-staging-favicon-admin";
  --asset-favicon-staging-default: "v1663155001/app/favicons/finishers-staging-favicon";
  --asset-favicon-prod-maskable: "v1663155001/app/favicons/finishers-prod-favicon-maskable";
  --asset-favicon-prod-admin: "v1663155001/app/favicons/finishers-prod-favicon-admin";
  --asset-favicon-prod-default: "v1663155001/app/favicons/finishers-prod-favicon";
  --color-text-tertiary: var(--color-storm-grey);
  --color-text-secondary: var(--color-fjord-grey);
  --color-text-default: var(--color-dark-blue);
  --color-bg-section-highlighted: var(--color-snow-grey);
  --color-bg-section-default: var(--color-white);
}
