@layer components {
  .form-group {
    @apply mb-5 md:mb-6;
  }

  .margin-dense .form-group {
    @apply mb-3;
  }

  .form-input {
    @apply px-3;
  }

  .form-input,
  .form-textarea,
  .form-checkbox,
  .form-multiselect,
  .form-select {
    @apply rounded-sm border-storm-grey/30 focus-visible:ring-electric-blue focus-visible:border-electric-blue;

    &.form-input-disabled,
    &:disabled {
      @apply text-storm-grey/70 border-storm-grey/20 cursor-not-allowed;
    }
  }

  .form-label {
    @apply block font-semibold text-sm md:text-base cursor-pointer mb-1 md:mb-2;
  }

  .form-help-text {
    @apply text-sm my-1 text-fjord-grey;
  }

  .form-error {
    @apply text-red-500;

    & .form-input,
    & .form-checkbox,
    & .form-textarea,
    & .form-multiselect,
    & .form-select {
      @apply border-red-500 focus:ring-red-500/50 focus:border-red-500;
    }

    & .form-help-text {
      @apply !text-red-500;
    }
  }

  .form-select {
    cursor: pointer;
    font-weight: bold;

    @apply focus:border-electric-blue;

    &:not(select) {
      position: relative;

      &:active,
      & {
        background-image: none !important;
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background-position-x: calc(100% - 8px);
        background-position-y: 50%;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        transition: transform 0.1s ease;
      }

      &[aria-expanded="true"]:after {
        transform: rotate(180deg);
      }
    }

    select&,
    &:not(select):after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FF6946' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    }

    select&:active {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath transform='rotate(180 10 10)' stroke='%23FF6946' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    }

    &.form-input-disabled,
    &[aria-disabled="true"],
    &:disabled {
      select&,
      &:not(select):after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
      }
    }
  }

  .form-select option {
    line-height: 2;
  }

  .form-radio {
    @apply text-electric-blue focus:ring-electric-blue focus:ring-opacity-40;
  }

  .form-checkbox {
    @apply text-electric-blue w-5 h-5;
  }
}
