@layer components {
  /* ========== */
  /* BUTTONS    */
  /* ========== */

  .button {
    @apply inline-flex items-center content-center justify-center;
    @apply leading-6 font-bold text-base text-dark-blue text-center uppercase;
    @apply transition-colors rounded-sm focus-visible:rounded-sm px-6 py-3 cursor-pointer;
    @apply focus-visible:outline-none;
    font-family: var(--font-family-title);
    font-size: 16px;
    text-decoration: none;
    line-height: 1.2;
  }
  .button:hover {
    text-decoration: none;
  }

  a.button,
  a.button:hover {
    text-decoration: none !important;
    text-decoration-line: none !important;
  }

  .button-small {
    @apply text-sm px-4 py-2;
  }

  .button-floating {
    @apply rounded-full;
  }

  .button-base {
    @apply text-base px-6 py-3;
  }

  .icon-button {
    @apply min-w-[48px] !px-0;
  }

  .button-icon-left {
    @apply mr-2;
  }
  .button-icon-right {
    @apply ml-2;
  }

  /* Default button */

  .button-default {
    @apply border-2 border-current focus:border-orange;
  }

  .button-hover.button-default,
  .button-default:hover {
    @apply border-orange;
  }

  .button-default.button-disabled,
  .button-default.button-disabled:hover,
  .button-default:disabled,
  .button-default[disabled] {
    @apply !text-storm-grey !border-storm-grey/80;
  }

  /* Secondary button */

  .button-secondary {
    @apply border-b-2 border-current px-1 rounded-none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyMiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMjAuMyA4SDEuN20xOC42IDAtNi42LTYuN00yMC4zIDhsLTYuNiA2LjciIHN0cm9rZT0iI0ZGNjk0NiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
    background-position: -25px center;
    transition:
      padding 0.2s,
      background-position 0.2s;
  }

  .button-hover.button-secondary,
  .button-secondary:hover {
    @apply border-orange;
    background-position: -0 center;
    padding-left: 35px;
  }

  .button-secondary.button-disabled,
  .button-secondary.button-disabled:hover,
  .button-secondary:disabled,
  .button-secondary[disabled] {
    @apply !text-storm-grey !border-storm-grey;
    background-position: -25px center !important;
    padding-left: 0 !important;
  }

  /* Grey button */

  .button-grey {
    @apply bg-storm-grey/10;
  }

  .button-hover.button-grey,
  .button-grey:hover {
    @apply bg-storm-grey/20;
  }

  .button-grey.button-disabled,
  .button-grey.button-disabled:hover,
  .button-grey:disabled,
  .button-grey[disabled] {
    @apply !text-storm-grey !bg-storm-grey/10;
  }

  /* Dark button */

  .button-dark {
    @apply bg-dark-blue text-white;
    transition: 0.2s background-color;
  }

  .button-hover.button-dark,
  .button-dark:hover {
    @apply bg-opacity-80;
  }

  /* Primary button */

  .button-primary {
    @apply text-white relative;
    background: rgb(var(--color-electric-blue))
      radial-gradient(rgb(var(--color-dark-blue)), rgb(var(--color-dark-blue)) 50%, transparent 53%)
      no-repeat center 80px;
    transition:
      background-position 0.4s,
      background-size 0.4s,
      color 0.15s 0.2s ease;
    background-size: 70% 70%;
  }

  .button-hover.button-primary,
  .button-primary:hover {
    background-position: center center;
    background-size: 200% 200%;
    @apply text-orange;
    transition:
      background-position 0.4s,
      background-size 0.4s,
      color 0.15s 0 ease;
  }

  .button-dark.button-disabled,
  .button-dark.button-disabled:hover,
  .button-dark:disabled,
  .button-dark[disabled],
  .button-primary.button-disabled,
  .button-primary.button-disabled:hover,
  .button-primary:disabled,
  .button-primary[disabled] {
    filter: brightness(1) !important;
    background: rgb(var(--color-storm-grey)) !important;
    @apply !text-white;
  }

  /* Danger button */

  .button-danger {
    @apply bg-red-700 text-white;
    transition: 0.2s background-color;
  }

  .button-danger:disabled {
    @apply bg-red-400;
  }

  .button-hover.button-danger,
  .button-danger:hover {
    @apply bg-opacity-80;
  }

  /* ========== */
  /* TYPOGRAPHY */
  /* ========== */

  .font-headers,
  .typo-h0,
  .typo-h1,
  .typo-h2,
  .typo-h3,
  .typo-h4 {
    font-family: var(--font-family-title) !important;
    font-weight: 700;
    font-stretch: 125%;
    text-transform: uppercase;
    @apply tracking-tight;
  }

  .typo-h1,
  .typo-h2 {
    font-style: italic;
  }

  .typo-h0 {
    @apply text-[114px] leading-none;
    @apply tracking-tighter;
  }
  .typo-h1 {
    @apply text-[42px] sm:text-6xl lg:text-7xl leading-[.9];
    hyphens: auto;
  }
  @media screen(sm) {
    .typo-h1 {
      hyphens: inherit;
    }
  }
  .typo-h2 {
    @apply text-2xl lg:text-4xl;
  }
  .typo-h3 {
    @apply text-2xl;
    font-weight: 600;
    font-stretch: 100%;
  }
  .typo-h4 {
    @apply text-lg;
    font-weight: 600;
    font-stretch: 100%;
  }

  .typo-body1,
  .typo-body2 {
    font-family: var(--font-family-body) !important;
  }

  .typo-body1 {
    @apply text-lg;
  }

  .typo-body2 {
    @apply text-base;
  }

  /* ======== */
  /* TABLES   */
  /* ======== */
  table.data-table {
    @apply w-full table-auto;
  }

  table.data-table thead {
    @apply bg-dark-blue text-white;
  }

  table.data-table thead th:not(.text-right) {
    @apply text-left;
  }

  table.data-table tbody tr {
    @apply odd:bg-snow-grey;
  }
  table.data-table td,
  table.data-table th {
    @apply px-4 py-2;
  }
  table.data-table td:first-child,
  table.data-table th:first-child {
    @apply pl-4;
  }
  table.data-table td:last-child,
  table.data-table th:last-child {
    @apply pr-4;
  }

  .data-table .secondary-data {
    @apply text-storm-grey;
  }
}
