@layer components {
  .link-with-arrow {
    text-wrap: pretty;
  }
  .link-with-arrow:after {
    content: "";
    display: inline-block;
    height: 0.5em;
    width: 0.5em;
    @apply border-t-2 border-r-2 border-orange rotate-45 align-middle;
    border-top-right-radius: 2px;
    margin-left: 0.5em;
  }
  .link-with-arrow-first:before {
    content: "";
    display: inline-block;
    height: 0.5em;
    width: 0.5em;
    @apply border-t-2 border-r-2 border-orange rotate-45 align-middle;
    border-top-right-radius: 2px;
    margin-right: 0.5em;
  }

  .link-with-arrow.link-with-arrow-width-3:after,
  .link-with-arrow-first.link-with-arrow-width-3:before {
    border-width: 3px;
  }

  .link-with-arrow-first.arrow-backward:before {
    @apply rotate-[225deg];
  }

  .SectionWhite .bg-light-contrast {
    @apply bg-snow-grey;
  }

  .SectionGrey .bg-light-contrast {
    @apply bg-white;
  }
}
