.Toastify__toast-theme--colored.Toastify__toast--success {
  background: rgb(var(--color-dark-blue)) !important;
  color: #ffffffcc !important;
}
.Toastify__toast-theme--colored.Toastify__toast--default,
.Toastify__toast-theme--colored.Toastify__toast--info {
  background: rgb(var(--color-dark-blue)) !important;
  color: #ffffffcc !important;
}
.Toastify__toast-theme--colored.Toastify__toast--default .Toastify__toast-icon,
.Toastify__toast-theme--colored.Toastify__toast--info .Toastify__toast-icon {
  color: white !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: rgb(var(--color-red-500)) !important;
  color: #ffffffcc !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error .Toastify__toast-icon {
  color: white !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: rgb(var(--color-orange)) !important;
  color: rgba(0, 0, 0, 0.78) !important;
}
.Toastify__toast {
  min-height: auto !important;
}
