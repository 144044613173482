:root {
  --navbar-height: 64px;
}

@media screen(lg) {
  :root {
    --navbar-height: 72px;
  }
}

@layer base {
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    font-family: var(--font-family-body) !important;
  }

  button {
    @apply button;
  }

  ::selection {
    @apply text-white bg-electric-blue;
  }
  ::-moz-selection {
    @apply text-white bg-electric-blue;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    @apply text-dark-blue;
  }

  a {
    @apply underline;
    text-underline-offset: 2px;
  }

  a:hover {
    text-decoration-line: underline;
    text-decoration-color: theme(colors.orange);
    text-decoration-thickness: 2px;
    /* text-decoration-skip-ink: none; */
  }

  @layer base {
    :root {
      --background: var(--color-white);
      --foreground: var(--color-dark-blue);

      --muted: var(--color-snow-grey);
      --muted-foreground: var(--color-fjord-grey);

      --popover: var(--color-white);
      --popover-foreground: var(--color-dark-blue);

      --border: var(--color-storm-grey);
      --input: var(--color-storm-grey);

      --card: var(--color-snow-grey);
      --card-foreground: var(--color-dark-blue);

      --primary: var(--color-electric-blue);
      --primary-foreground: var(--color-white);

      --secondary: var(--color-orange);
      --secondary-foreground: var(--color-white);

      --accent: var(--color-orange);
      --accent-foreground: var(--color-white);

      --destructive: var(--color-red-500);
      --destructive-foreground: var(--color-white);

      --ring: var(--color-blue-600);
    }
    /*  
  .dark {
    --background: rgba(var(--color-green-50));
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: rgba(var(--color-green-50));
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: rgba(var(--color-green-50));
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  } */
  }
}
