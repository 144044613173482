@import "@kavval/design-tokens/dist/variables.css";

@import "tailwindcss/base";
@import "@kavval/ui/src/tailwind/base.css";
@import "./tailwind-base.css";

@import "tailwindcss/components";
@import "@kavval/ui/src/tailwind/components.css";
@import "@kavval/ui/src/tailwind/forms.css";
@import "./tailwind-components.css";

@import "tailwindcss/utilities";
@import "@kavval/ui/src/tailwind/utilities.css";

@import "./nprogress.css";

/* WisePops */
.wisp:not(.wisp_ready) {
  display: none;
}
.wisp {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.wisp_ready {
  opacity: 1;
}
