:root {
  /* const oxfordBlue = "#0A122B"; // 10 18 43
  const aquamarine = "#26E09C"; // 38 224 156
  const stormGrey = "#6E768F"; // 110 118 143
  const fjordGrey = "#464E67"; // 70 78 103
  const lightGreyBackground = "#F3F3F4"; // 243 243 244 */

  --cc-bg: #ffffff;
  --cc-btn-primary-bg: rgb(var(--color-electric-blue));
  --cc-btn-primary-text: #ffffff;
  --cc-btn-primary-hover-bg: #1d2e38;
  --cc-btn-secondary-bg: #dfe7f9;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #c6d1ea;
  --cc-toggle-bg-off: #6e768f;
  --cc-toggle-bg-on: rgb(var(--color-electric-blue));
  --cc-toggle-bg-readonly: rgb(var(--color-electric-blue));
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-cookie-category-block-bg: #f3f3f4;
  --cc-cookie-category-block-bg-hover: #ebebf2;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e1e7f3;
  --cc-overlay-bg: rgba(230, 235, 255, 0.85);
  --cc-webkit-scrollbar-bg: #ebeff9;
  --cc-webkit-scrollbar-bg-hover: rgb(var(--color-electric-blue));
}

html #c-ttl,
html #s-ttl {
  /* color: var(--cc-btn-primary-bg); */
  font-size: 20px;
  letter-spacing: -0.3px;
  font-weight: 700 !important;
}

.cc_div .b-tl,
#b-tl {
  font-weight: 700 !important;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  @apply !bg-electric-blue;
}

/* Custom border radius */
html #cm,
html #s-bl .act .b-acc,
html #s-inr,
html .cc_div .b-tl,
html .cc_div .c-bl {
  border-radius: 2px;
}

#c-txt {
  font-size: 14px !important;
}
#c-inr-i {
  vertical-align: middle !important;
}

.cc_div * {
  font-family: var(--font-family-body) !important;
}

#cc--main {
  z-index: 2147483002 !important;
}

@media screen and (max-width: 688px) {
  #cm.bottom {
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 18px !important;
  }
  #s-inr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 !important;
    padding: 0 !important;
  }
  #s-inr > div {
    height: auto !important;
    flex-shrink: 0;
    padding: 16px !important;
    position: static;
  }
  #s-inr > #s-bl {
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
  }
  #s-inr > #s-bns {
    /* flex-grow: 1 !important;
    flex-shrink: 1 !important; */
  }
}

.c-bn,
.c-bn:hover {
  @apply !text-white !bg-electric-blue;
  transition: 0.2s filter;
  border-radius: 2px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  padding: 0.8em 1.7em !important;
}
#s-c-bn,
#s-rall-bn,
.c-bn.c_link {
  @apply !bg-storm-grey/10 !text-dark-blue;
  background-image: none !important;
}
#s-c-bn {
  padding: 0 !important;
}
.c-bn:hover {
  filter: brightness(0.9);
}
